import { defineMessages } from "react-intl";
import { reactIntl } from "utils/helpers/i18n";

export default reactIntl.i18nMessages(
  defineMessages({
    slogan: {
      id: "footer.slogan",
      defaultMessage:
        " Design amazing digital experiences that create more happy in the world.",
    },
    getAppTitle: {
      id: "footer.getAppTitle",
      defaultMessage: " Get the app",
    },
    about: {
      id: "footer.about",
      defaultMessage: "About",
    },
    privacy: {
      id: "footer.privacy",
      defaultMessage: "Privacy Policy",
    },
    terms: {
      id: "footer.terms",
      defaultMessage: " Terms and Conditions",
    },
    return: {
      id: "footer.return",
      defaultMessage: "Return Policy",
    },
    warrenty: {
      id: "footer.warrenty",
      defaultMessage: "Warranty Policy",
    },
    maintenance: {
      id: "footer.maintenance",
      defaultMessage: "Maintenance Policy",
    },
    contact: {
      id: "footer.contact",
      defaultMessage: "Contact us",
    },
    policies: {
      id: "footer.policies",
      defaultMessage: "Store Policies",
    },
    contactInfo: {
      id: "footer.contactInfo",
      defaultMessage: "Contact Information",
    },
    findUs: {
      id: "footer.findUs",
      defaultMessage: "Find Us On Map",
    },
  })
);
